<template>
  <div>
    <KTCalendarDayForm
      @saved="onSaved"
      v-bind:aboveTable="true"
      v-bind:editingId="editingId"
    />
    <div class="row">
      <div class="col-md-12">
        <vue-good-table
          mode="remote"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          :totalRows="totalRecords"
          :isLoading.sync="isLoading"
          :pagination-options="{
            enabled: true,
            perPage: 20,
            perPageDropdown: [20],
            dropdownAllowAll: false
          }"
          :rows="rows"
          :columns="columns"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
              <div>
                <!-- <router-link
                  :to="{
                    name: 'calendarDayForm',
                    params: { id: props.formattedRow.id }
                  }"
                >
                  <b-button variant="outline-primary">Edit</b-button>
                </router-link> -->

                <b-button
                  @click="edit(props.formattedRow, $event)"
                  variant="outline-primary"
                  >Edit</b-button
                >
                <b-button
                  @click="confirmDelete(props.formattedRow)"
                  variant="outline-danger"
                  >Remove</b-button
                >
              </div>
            </span>
            <span v-else>{{ props.formattedRow[props.column.field] }}</span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import KTCalendarDayForm from "@/view/pages/CalendarDayForm.vue";
import "vue-good-table/dist/vue-good-table.css";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  components: {
    KTCalendarDayForm
  },
  data() {
    return {
      editingId: "",
      isLoading: true,
      columns: [
        {
          label: "ID",
          field: "id"
        },
        {
          label: "type",
          field: "containerId"
        },
        {
          label: "title",
          field: "title",
          sortable: false
        },
        {
          label: "Date",
          field: "date",
          type: "date",
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "dd MMM yyyy"
        },
        {
          label: "Actions",
          field: "actions",
          sortable: false
        }
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        attributes: "id,containerId,title,date",
        sort: {
          field: "id",
          type: "desc"
        },
        page: 1,
        perPage: 20
      }
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Calendar Days",
        actions: [
          {
            to: "/calendarDayForm",
            name: "New Event",
            btnClass: "btn-success"
          }
        ]
      }
    ]);
    this.loadItems();
  },
  methods: {
    edit(row, e) {
      if (e.shiftKey) {
        this.$router.push({
          name: "calendarDayForm",
          params: { id: row.id }
        });
      } else {
        this.editingId = row.id;
      }
    },
    confirmDelete(row) {
      if (confirm(`Are you sure you want to delete this event ?`)) {
        return new Promise(resolve => {
          ApiService.post("calendarDays/delete", { id: row.id })
            .then(({ data }) => {
              if (data && data.response && data.response.status) {
                this.$bvToast.toast("It has been removed succesfully!", {
                  title: "Success",
                  variant: "success"
                });
              }
              this.loadItems();
              resolve();
            })
            .catch(e => {
              console.log(e.message);
            });
        });
      }
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },
    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field
        }
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    onSaved() {
      this.loadItems();
    },

    // load items is what brings back the rows from server
    loadItems() {
      this.isLoading = true;
      return new Promise(resolve => {
        ApiService.get("calendarDays", this.serverParams)
          .then(({ data }) => {
            this.rows = data.response.items;
            this.totalRecords = data.response.itemCount;
            this.isLoading = false;
            resolve();
          })
          .catch(e => {
            console.log(e.message);
          });
      });
    }
  }
};
</script>
