var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("KTCalendarDayForm", {
        attrs: { aboveTable: true, editingId: _vm.editingId },
        on: { saved: _vm.onSaved },
      }),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c("vue-good-table", {
              attrs: {
                mode: "remote",
                totalRows: _vm.totalRecords,
                isLoading: _vm.isLoading,
                "pagination-options": {
                  enabled: true,
                  perPage: 20,
                  perPageDropdown: [20],
                  dropdownAllowAll: false,
                },
                rows: _vm.rows,
                columns: _vm.columns,
              },
              on: {
                "on-page-change": _vm.onPageChange,
                "on-sort-change": _vm.onSortChange,
                "on-column-filter": _vm.onColumnFilter,
                "on-per-page-change": _vm.onPerPageChange,
                "update:isLoading": function ($event) {
                  _vm.isLoading = $event
                },
                "update:is-loading": function ($event) {
                  _vm.isLoading = $event
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "table-row",
                  fn: function (props) {
                    return [
                      props.column.field == "actions"
                        ? _c("span", [
                            _c(
                              "div",
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: { variant: "outline-primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.edit(
                                          props.formattedRow,
                                          $event
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Edit")]
                                ),
                                _c(
                                  "b-button",
                                  {
                                    attrs: { variant: "outline-danger" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.confirmDelete(
                                          props.formattedRow
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Remove")]
                                ),
                              ],
                              1
                            ),
                          ])
                        : _c("span", [
                            _vm._v(
                              _vm._s(props.formattedRow[props.column.field])
                            ),
                          ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }